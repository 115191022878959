import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = ({ facebook, youtube, instagram, title }) => {
  return (
    <footer className="footer footer-center p-10 rounded">
      <div>
        <div className="grid grid-flow-col gap-4">
          <a
            href={instagram}
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: "2rem" }}
            className="text-indigo-400"
          >
            <FaInstagram />
          </a>
          <a
            href={youtube}
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: "2rem" }}
            className="text-red-400"
          >
            <FaYoutube />
          </a>
          <a
            href={facebook}
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: "2rem" }}
            className="text-blue-400"
          >
            <FaFacebookSquare />
          </a>
        </div>
      </div>
      <div>
        <p className="text-purple-300">
          Copyright © 2022 - All right reserved by {title}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
