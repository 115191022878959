const Countdown = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="grid grid-flow-col gap-5 text-center justify-center auto-cols-max">
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl text-yellow-400">
          <span style={{ "--value": days }}></span>
        </span>
        days
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl text-red-200">
          <span style={{ "--value": hours }}></span>
        </span>
        hours
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl text-green-400">
          <span style={{ "--value": minutes }}></span>
        </span>
        min
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl text-orange-200">
          <span style={{ "--value": seconds }}></span>
        </span>
        sec
      </div>
    </div>
  );
};

export default Countdown;
