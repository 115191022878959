import Countdown from "./Components/Countdown";
import { useCountdown } from "./hooks/useCountdown";
import { request, gql } from "graphql-request";
import Footer from "./Components/Footer";
import { useEffect, useState } from "react";

function App() {
  const [launchDate, setLaunchDate] = useState(
    new Date("Dec 25, 2022").getTime()
  );
  const [days, hours, minutes, seconds] = useCountdown(launchDate);
  const [homepageData, setHomePageData] = useState(null);

  const endpoint = process.env.REACT_APP_BASE_URL;
  const HOMEPAGE_QUERY = gql`
    {
      homepages(where: { slug: "pixwow-studios" }) {
        createdAt
        description
        launchdate
        facebook
        instagram
        publishedAt
        slug
        title
        id
        updatedAt
        youtube
        background {
          url
        }
        logo {
          url
          fileName
        }
      }
    }
  `;

  useEffect(() => {
    try {
      const fetchHomeData = async () => {
        const data = await request(endpoint, HOMEPAGE_QUERY);
        setHomePageData(data?.homepages[0]);
        setLaunchDate(data?.homepages[0]?.launchdate);
      };
      fetchHomeData();
      return () => {};
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {homepageData ? (
        <div
          className="hero min-h-screen"
          style={{ backgroundImage: `url(${homepageData?.background.url})` }}
        >
          <div className="hero-overlay bg-opacity-75"></div>
          <div className="hero-content text-center text-neutral-content">
            <div className="max-w-lg py-6">
              <div className="avatar justify-center py-4">
                <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img
                    src={homepageData?.logo?.url}
                    alt={homepageData?.logo?.fileName}
                  />
                </div>
              </div>
              <h1 className="mb-5 text-5xl text-red-300 font-bold">
                {homepageData.title}
              </h1>
              <p className="mb-5 text-blue-200">{homepageData?.description}</p>
              <Countdown
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
              <Footer {...homepageData} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <progress className="progress progress-success w-full py-2 my-2"></progress>
          <progress className="progress progress-success w-full py-2 my-2"></progress>
          <progress className="progress progress-success w-full py-2 my-2"></progress>
          <progress className="progress progress-success w-full py-2 my-2"></progress>
          <progress className="progress progress-success w-full py-2 my-2"></progress>
        </div>
      )}
    </>
  );
}

export default App;
